import { useState, useEffect, useContext } from "react";
import { Button, Form, Icon, Dropdown } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "./mdl-change-status.css";
import { AppContext } from "../../../../Context/AppContext";
import { submitChangeStatus } from "../../../../constants";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

export default function MDL_CHANGE_STATUS({
  set_selected_order,
  selected_order,
  set_mdl_Open,
  getSchedulerOrders,
}) {
  const [units, setUnits] = useState([]);
  const [status, setStatus] = useState();
  const [available_m_units, setAvailableMUnits] = useState([]);
  const [available_e_units, setAvailableEUnits] = useState([]);
  const [options_units, setOptionsUnits] = useState([]);
  const [currentDate, setNewDate] = useState(null);
  const [selected_units, set_selected_units] = useState([]);
  const [selected_units_e, set_selected_units_e] = useState([]);
  const [errors, set_errors] = useState({});
  const { addToast } = useToasts();

  useEffect(() => {
    if (selected_order) {
      var temp_u = [];
      selected_order.units.map((val) => {
        temp_u = [
          ...temp_u,
          {
            ...val,
            key: val.order_unit_id,
            text: val.unit_number,
            value: val.order_unit_id,
          },
        ];
      });
      setOptionsUnits(temp_u || []);
    }
  }, [selected_order]);

  useEffect(() => {
    if (options_units.length) {
      setAvailableEUnits(options_units);
      setAvailableMUnits(options_units);
    }
  }, [options_units]);

  function onChangeStatus(e, { value }) {
    var temp_error = { ...errors };
    temp_error.status = null;
    set_errors(temp_error);
    setStatus(value);
  }

  function isValidated() {
    var temp_error = { ...errors };
    var isValid = true;

    if (!status) {
      temp_error.status = "Please select status.";
      isValid = false;
    }
    if (!currentDate) {
      temp_error.currentDate = "Please select date.";
      isValid = false;
    }
    if (selected_units_e.length == 0 && selected_units.length == 0) {
      temp_error.morning_units = "Please select units.";
      temp_error.evening_units = "Please select units.";
      isValid = false;
    }
    if (
      selected_units_e.length + selected_units.length <
      selected_order.units.length
    ) {
      temp_error.evening_units = "Please select units.";
      isValid = false;
    }

    if (isValid) {
      set_errors({});
    } else {
      set_errors(temp_error);
    }
    return isValid;
  }

  const onSubmit_update_status = async () => {
    if (isValidated()) {
      var temp_m_units = [];
      var temp_e_units = [];
      if (selected_units.length > 0) {
        selected_units.map((val_m) => {
          var selected_value = options_units.filter(
            (i_val) => i_val.value === val_m
          );
          temp_m_units.push({
            unit_number: selected_value[0]?.unit_number,
            time_range: selected_order.TimeWindow1,
            pest_name: selected_value[0]?.pest_name,
            treatment_name: selected_value[0]?.treatment_name,
            order_unit_id: selected_value[0]?.order_unit_id,
          });
        });
      }
      if (selected_units_e.length > 0) {
        selected_units_e.map((val_e) => {
          var selected_value = options_units.filter(
            (i_val) => i_val.value === val_e
          );
          temp_e_units.push({
            unit_number: selected_value[0]?.unit_number,
            time_range: selected_order.TimeWindow2,
            pest_name: selected_value[0]?.pest_name,
            treatment_name: selected_value[0]?.treatment_name,
            order_unit_id: selected_value[0]?.order_unit_id,
          });
        });
      }

      let m_units = temp_m_units; //morning units
      let e_units = temp_e_units; // Evening units
      m_units = m_units.reduce(function (prev, value) {
        var isDuplicate = false;
        for (var i = 0; i < e_units.length; i++) {
          if (
            value.unit_number == e_units[i].unit_number &&
            value.pest_name == e_units[i].pest_name &&
            value.treatment_name == e_units[i].treatment_name
          ) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          prev.push(value);
        }
        return prev;
      }, []);
      // console.log("morning:::", m_units);
      const final_concated_units = e_units.concat(m_units);
      var params = {};
      params = { ...params, order_id: selected_order.id };
      params = { ...params, work_date: currentDate };
      params = {
        ...params,
        order_status: parseInt(status) == 1 ? "scheduled" : "completed",
      };
      params = { ...params, units: final_concated_units };
      const requestOptions = {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify(params),
      };
      // await fetch(submitChangeStatus+`?order_id=${selected_order.id}&work_date=${currentDate}&order_status=${parseInt(status) ==1?"in progress" : "completed"}&units=${final_concated_units}`)
      await fetch(submitChangeStatus, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 200) {
            addToast(res.success, {
              appearance: "success",
              autoDismiss: true,
            });
            getSchedulerOrders();
            closeModal();
          } else {
            addToast(res.failed, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  function closeModal() {
    set_selected_order(null);
    set_mdl_Open(false);
  }

  const onChangeMorningUnits = async (event, val) => {
    const { value } = val;
    var temp_error = { ...errors };
    temp_error.morning_units = null;
    set_errors(temp_error);
    const filteredUnits = options_units.filter(
      (unit) => !value.includes(unit.value)
    );
    setAvailableEUnits(filteredUnits); // Update available units here
    set_selected_units(value);
  };
  const onChangeEveningUnits = (event, val) => {
    const { value } = val;
    var temp_error = { ...errors };
    temp_error.evening_units = null;
    set_errors(temp_error);
    const filteredUnits = options_units.filter(
      (unit) => !value.includes(unit.value)
    );
    setAvailableMUnits(filteredUnits); // Update available units here
    set_selected_units_e(value);
  };

  const onChange = (event, data) => {
    if (data?.value) {
      var temp_error = { ...errors };
      temp_error.currentDate = null;
      set_errors(temp_error);
      setNewDate(moment(data?.value).format("YYYY-MM-DD hh:mm"));
    }
  };
  return (
    <>
      <div className="modal-header">
        <div className="modal-header-title">Update Status</div>
        <div className="modal-header-close" onClick={() => closeModal()}>
          <Icon name="times circle outline" />
        </div>
      </div>
      <div className="modal-content">
        <Form>
          <Form.Group>
            <Form.Select
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              placeholder="Select Status"
              required={true}
              error={errors.status}
              value={status}
              label="Status"
              onChange={onChangeStatus}
              options={[
                { key: 1, text: "Scheduled", value: 1 },
                { key: 2, text: "Completed", value: 2 },
              ]}
            />
          </Form.Group>
          <Form.Group>
            <SemanticDatepicker
              id="date"
              name="date"
              label="Date &amp; Time"
              required={true}
              fluid
              error={errors.currentDate}
              onChange={onChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Dropdown
              label="Morning Units"
              required={true}
              placeholder="Morning Units"
              fluid
              multiple
              search
              selection
              error={errors.morning_units}
              onChange={onChangeMorningUnits}
              options={available_m_units}
            />
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              label="Afternoon Units"
              required={true}
              placeholder="Afternoon Units"
              fluid
              multiple
              search
              selection
              error={errors.evening_units}
              onChange={onChangeEveningUnits}
              options={available_e_units}
            />
          </Form.Group>
        </Form>
      </div>

      <div className="modal-footer">
        <Button color="red" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button color="green" onClick={onSubmit_update_status}>
          Submit
        </Button>
      </div>
    </>
  );
}
