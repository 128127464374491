import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { downloadSummaryAPI, getBuildingSummaryAPI,getAllPestsTreatment,getAllPests } from "../../../constants";
import "./BuildingHistory.css";
import moment from "moment";
import { uniqueId } from "lodash";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import { useParams, useHistory } from "react-router-dom";

const BuildingHistory = () => {
  const { building_id } = useParams();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const { selectedBuilding, bInfo,cartItems,checkedUnitsData,sliderCart,UncheckedUnitsData,ShowHeaderSearch,userInfo } = useContext(AppContext);
  const [user] = userInfo;
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [sideSliderCart, setSideSliderCart] = sliderCart;
  const [building_summary, setBuildingSummary] = useState([]);
  const [checkedUnits, setCheckedUnits] = checkedUnitsData;
  const [, setBuildingInfo] = bInfo;
  const [cart_items, setCartItems] = cartItems;
  const [UncheckedUnits,setUnCheckedUnits ] = UncheckedUnitsData;
  const [var_ShowHeaderSearch, setShowHeaderSearch]=ShowHeaderSearch;
  const [disabledButtons, setDisabledButtons] = useState({});
  const [filter, setFilter] = useState([
    {
      value: 3,
      text: "3 Months",
    },
    {
      value: 6,
      text: "6 Months",
    },
    {
      value: 9,
      text: "9 Months",
    },
    {
      value: 12,
      text: "12 Months",
    },
  ]);
  const [selected_filter, setSelectedFilter] = useState(3);

  useEffect(() => {
    if (selected_filter) getBuildingSummary();
  }, [selected_filter]);

  useEffect(() => {
    prepareBuildingInfo();
  }, []);



  useEffect(() => {
  
    if (UncheckedUnits && UncheckedUnits !== 'All') {
        const updatedCheckedUnits = {...disabledButtons};
        const [floor, unit] = UncheckedUnits?.split('-');
        const unitKey = `${floor}-${unit}`;
        delete updatedCheckedUnits[unitKey];
        setDisabledButtons((prevState) => {
            return updatedCheckedUnits;
        });
        setCheckedUnits(updatedCheckedUnits);
    } else if (UncheckedUnits === 'All') {
        setDisabledButtons({});
        setUnCheckedUnits(null);
    } 
}, [UncheckedUnits, building_id]);

useEffect(() => {
  if (checkedUnits) {
      setDisabledButtons(checkedUnits);
    }
  }, [checkedUnits]);

  async function prepareBuildingInfo() {
    if (!selected_building && localStorage.getItem("building")) {
      const buildings = await JSON.parse(localStorage.getItem("building"));
      setBuildingInfo(buildings);
      let temp_building = [];
      if (buildings && buildings.length > 0) {
        temp_building = buildings.filter((b) => b.value === building_id);
      }
      if (temp_building.length) {
        setSelectedBuilding(temp_building[0]);
        getBuildingSummary();
      } else {
        history.back();
      }
    } else {
      getBuildingSummary();
    }
  }

  async function downloadReport() {
    const url = `${
      downloadSummaryAPI + building_id
    }?month=${selected_filter}&download=true`;
    // console.log("Url", url);
    try {
      //.Use This to View the Report
      // const res = await fetch(url);
      // const responseType = res.headers.get("Content-Type") || res.headers.get("content-type");
      //   if (responseType.indexOf('application/json') === -1) {
      //       return res.blob();
      //   }
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getBuildingSummary() {
    set_mdl_progress(true);
    let API_URL = getBuildingSummaryAPI + building_id;
    if (selected_filter) {
      API_URL += `?month=${selected_filter}`;
    }

    await fetch(API_URL)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) setBuildingSummary(response.data);
        else {
          setBuildingSummary([]);
        }
        set_mdl_progress(false);
      });
  }

  function onChangeFilter(event, { value }) {
    if (value) setSelectedFilter(value);
  }

  async function getPestNameFromComments(splitCommentWithFollowUp) {
    var pest = "";
    if (
      splitCommentWithFollowUp.includes("bb") ||
      splitCommentWithFollowUp.includes("bed bug")
    ) {
      pest = "Bed Bugs";
    } else if (
      splitCommentWithFollowUp.includes("cr") ||
      splitCommentWithFollowUp.includes("cockroach")
    ) {
      pest = "Cockroaches";
    } else if (splitCommentWithFollowUp.includes("mice")) {
      pest = "Mice";
    } else if (splitCommentWithFollowUp.includes("rat")) {
      pest = "Rats";
    } else if (
      splitCommentWithFollowUp.includes("pharaoh ants") ||
      splitCommentWithFollowUp.includes("pharaoh ant")
    ) {
      pest = "Pharaoh Ants";
    } else if (
      splitCommentWithFollowUp.includes("ant") ||
      splitCommentWithFollowUp.includes("ants")
    ) {
      pest = "Ants";
    } else if (splitCommentWithFollowUp.includes("other pest")) {
      pest = "Other Pest";
    }
    else if (splitCommentWithFollowUp.includes("fleas")) {
      pest = "Fleas";
    }
    else if (splitCommentWithFollowUp.includes("flies")) {
      pest = "Flies";
    }
    else if (splitCommentWithFollowUp.includes("stored product pests (beetles, weevils, etc.)")) {
      pest = "Stored Product Pests (Beetles, Weevils, etc.)";
    }
    else if (splitCommentWithFollowUp.includes("crawling insects (spiders, silverfish, centipedes, etc.)")) {
      pest = "Crawling Insects (Spiders, Silverfish, Centipedes, etc.)";
    }
    return pest;
  }

  async function getTreatmentFromComments(splitCommentWithFollowUp) {
    var treatment = "";
    if (splitCommentWithFollowUp.includes("inspection")) {
      treatment = "Inspection";
    }  else if (
      splitCommentWithFollowUp.includes("residual spray") ||
      splitCommentWithFollowUp.includes("residual spray treatment")
    ) {
      treatment = "Residual Spray Treatment";
    } else if (
      (splitCommentWithFollowUp.includes("spray") ||
      splitCommentWithFollowUp.includes("spray treatment")) && (!splitCommentWithFollowUp.includes("residual spray") || !splitCommentWithFollowUp.includes("residual spray treatment"))
    ) {
      treatment = "Spray Treatment";
    } else if (
      splitCommentWithFollowUp.includes("flush and vac") ||
      splitCommentWithFollowUp.includes("flush and vac treatment") ||
      splitCommentWithFollowUp.includes("Spray, Flush and Vac Treatment")
    ) {
      treatment = "Residual Spray Treatment";
    } else if (
      splitCommentWithFollowUp.includes("dust and gel") ||
      splitCommentWithFollowUp.includes("gel and dust treatment")
    ) {
      treatment = "Gel and Dust Treatment";
    } else if (splitCommentWithFollowUp.includes("monitor installation")) {
      treatment = "Monitor Installation";
    } else if (
      splitCommentWithFollowUp.includes("preventative") ||
      splitCommentWithFollowUp.includes("preventative treatment")
    ) {
      treatment = "Preventative Treatment";
    } else if (
      splitCommentWithFollowUp.includes("exacticide") ||
      splitCommentWithFollowUp.includes("exacticide treatment")
    ) {
      treatment = "Exacticide Treatment";
    } else if (
      splitCommentWithFollowUp.includes("bait") ||
      splitCommentWithFollowUp.includes("bait treatment")
    ) {
      treatment = "Bait Treatment";
    } else if (splitCommentWithFollowUp.includes("treatment")) {
      treatment = "Treatment";
    } else if (splitCommentWithFollowUp.includes("bioShield")) {
      treatment = "BioShield Treatment";
    } else if (splitCommentWithFollowUp.includes("bioShield treatment")) {
      treatment = "BioShield Treatment";
    } else if (splitCommentWithFollowUp.includes("actisol")) {
      treatment = "Actisol Treatment";
    } else if (splitCommentWithFollowUp.includes("actisol treatment")) {
      treatment = "Actisol Treatment";
    }
    return treatment;
  }

  async function getPestTreatments(treatmentName) {
    var treatmentID = "";
    await fetch(getAllPestsTreatment)
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          const treatment = res.data.find(p => p.treatmentName?.toLowerCase() === treatmentName.toLowerCase());
          if (treatment) {
            
            treatmentID = treatment.treatmentId;
          }
        } 
      });
      return treatmentID;
  }
  async function getPests(pestName) {
    var pestID = "";
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          const pest = res.data.find(p => p.pestName?.toLowerCase() === pestName.toLowerCase());
          if (pest) {
            pestID= pest.pestId
          }
        } 
      });
      return pestID;
  }

  async function commentData(comment,pest_name,treatment_name) {
    var pest = "";
    var pestName = "";
    var treatment = "";
    var treatmentName = "";
    var techCommentDetails = "neworder";
    var commentsLowerCase = comment?.toLowerCase() || '';
    if (commentsLowerCase.includes("follow up") && !commentsLowerCase.includes("no follow up")) {
      if (commentsLowerCase.includes("follow up inspection") || commentsLowerCase.includes("follow-up inspection")) {
        pestName = "Other Pest";
          treatmentName ="Inspection";
          if (pestName) {
            pest = await getPests(pestName);
            treatment = await getPestTreatments(treatmentName);
          }
          techCommentDetails = "followup";
      } else{      
        var commentFollowUp = commentsLowerCase.split("follow up");      
        if (commentFollowUp[1]) {
          pestName = await getPestNameFromComments(commentFollowUp[1]);
          treatmentName = await getTreatmentFromComments(commentFollowUp[1]);
          if(pestName){
            pest = await getPests(pestName) 
            treatment = await getPestTreatments(treatmentName)
          }
          techCommentDetails = "followup";
        }
      }
    }else if (commentsLowerCase.includes("not completed")) {   
      if(pest_name && treatment_name){
        pest = await getPests(pest_name) 
        treatment = await getPestTreatments(treatment_name)
      }   
      techCommentDetails = "followup_immediately";
    } else if (commentsLowerCase.includes("reschedule required")) {    
      if(pest_name && treatment_name){
        pest = await getPests(pest_name) 
        treatment = await getPestTreatments(treatment_name)
      }    
      techCommentDetails ="reschedule";
    }
  
    return [pest,pestName,treatment,treatmentName,techCommentDetails];
  }



  async function addUnittoOrder(floor, unit_number,comment,pest_name,treatment_name) {
    var [pest,pestName,treatment,treatmentName,techCommentDetails] ='';
    if(comment){
      [pest,pestName,treatment,treatmentName,techCommentDetails] = await commentData(comment,pest_name,treatment_name);
    }
    //
    const appendedObject = { unit_number, followup: techCommentDetails,pest:pestName? pestName :pest_name,treatment:treatmentName ? treatmentName : treatment_name,pest_id:pest,treatment_id:treatment, floor, key: uniqueId() };
    var cartData = cart_items ? { ...cart_items } : {};
    //If cartData is null then we have no need to compare the floor
    if (!cartData || !Object.keys(cartData).includes(floor)) {
      cartData = { ...cartData, [floor]: [appendedObject] };
    }
    //Now the turn is cartData have some data
    else {
      //If floor that is clicked exist in
      if (Object.keys(cartData).includes(floor)) {
        var floorData = cartData[floor];
        floorData = [...floorData, appendedObject];
        cartData = { ...cartData, [floor]: floorData };
      } else {
        cartData = { ...cartData, [floor]: [appendedObject] };
      }
    }
    const checkedFloor = `${floor}-${unit_number}`;
    setCheckedUnits({...checkedUnits, [checkedFloor]: true });
    setCartItems(cartData);
    setSideSliderCart(true)
    // Disable the button
   setDisabledButtons({ ...disabledButtons, [checkedFloor]: true });
  };


  return (
    <div className="app-content">
      <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            setShowHeaderSearch(!var_ShowHeaderSearch);
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
      <div className="app-card">
        <div className="app-card-header">
          <div className="app-card-title building-name building-history">
            <span className="building-name-spacing">
              Building: {selected_building?.text}
            </span>

            <span className="building-date-spacing">
              Requested Date: {moment().format("LL")}
            </span>
          </div>
          <div className="app-content-action-right">
            <Form.Select
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              placeholder="Select Filter"
              label="Time Range: "
              onChange={onChangeFilter}
              value={selected_filter}
              options={filter}
            />
            {/* Button to download the history */}
            <Button
              color="green"
              type="button"
              content="Download"
              onClick={() => {
                downloadReport();
              }}
              icon="download"
            ></Button>
          </div>
        </div>

        <Grid style={{ margin: "0" }}>
          {building_summary?.map((summary, index) => {
        const checkedFloor = `${summary?.floor}-${summary.unit_number}`;
        return (
            <>
            <Grid.Row className="row-p0 border-top" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Grid.Column style={{ paddingTop: "4px" }}>
                      <span className="unit-number-spacing">
                          {summary.unit_number}
                      </span>
                  </Grid.Column>
                  {user.role === "customer" && summary.comment && summary.comment !== "" &&
                      <Button color="blue" style={{ padding: "10px", fontSize: "13px", marginTop: "10px" }}  onClick={() => addUnittoOrder(summary?.floor,summary.unit_number,summary.comment,summary.pest_name,summary.treatment_name)}  disabled={summary?.comment?.toLowerCase().includes("no follow up") ? true :disabledButtons[checkedFloor]}>
                        Request Follow-up
                      </Button>
                  }
              </Grid.Row>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Order#</span>
                  <span>#{summary.order_number}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Treatment</span>
                  <span>
                    {summary.pest_name} {summary.treatment_name}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Work Date</span>
                  <span>{moment(summary.work_date).format("MM-DD-YYYY")}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Activity Level</span>
                  <span>{summary.activity_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Sanitation</span>
                  <span>{summary.sanitation_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Structural</span>
                  <span>
                    {(summary.structural_issues && "Structural Issues") ||
                      "N/A"}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Prep Level</span>
                  <span>{summary.prep_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                className="comment-spacing comment-text"
              >
                <span>{summary.comment || "No Comment"}</span>
              </Grid.Column>
              {/* </Grid.Row> */}
            </>
            );
          })}
        </Grid>
        {building_summary.length === 0 && !var_mdl_progress && (
          <Grid textAlign="center">
            <span className="no-data">
              No building history. Please change time range.
            </span>
          </Grid>
        )}
      </div>

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
};

export default BuildingHistory;
