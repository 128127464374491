import React from 'react'
import { Form, Icon, Button } from 'semantic-ui-react'

const MDL_VIEW_DETAILS = ({ set_mdl_open,data,title}) => {
/* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
   
/* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

 /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

/* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    function closeModal() {
        set_mdl_open(false);
    }



/* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <Form>
                <div className="modal-header">
                    <div className="modal-header-title">{title}</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                        <Icon name="times circle outline" />
                    </div>
                </div>
                <div className='modal-content'>
                    <Form.Group>
                    <Form.TextArea
                        id={data}
                        name={data}
                        width={16}
                        style={{ minHeight: 150 }}
                        readOnly
                        value={data}
                        fluid
                        />
                    </Form.Group>
                </div>
                <div className="modal-footer">
                    <Button color="red" onClick={() => closeModal()}>Close</Button>
                </div>
            </Form>
        </>
    )
}

export default MDL_VIEW_DETAILS