import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Icon,
  Message,
  Modal,
  FormGroup,
  Table,
} from "semantic-ui-react";
import { useContext } from "react";
import { AppContext } from "../../../../Context/AppContext";
import ADD_UNIT from "./cmp-add-unit/cmp-add-unit";
import "./OrderRequestForm.css";
import { portalReadPdf } from "../../../../constants";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import { useToasts } from "react-toast-notifications";
import { DraggableRow } from "../../CriticalList/draggable-row/draggable-row";

function ImpersonateOrderRequestForm() {
  const fileInputRef = useRef(null);
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    error_validation,
    userInfo,
    cartItems,
    UncheckedUnitsData,
    checkedUnitsData,
    sbuildingData,
    // selectedBuilding,
  } = useContext(AppContext);
  const [user] = userInfo;
  const [single_building_data, ] = sbuildingData;
  const [selected_building, setSelectedBuilding] = useState({});
  const [pestTreated, setPestTreated] = useState();
  const [form_validation_error, setFormError] = error_validation;
  const [treatmentOption, setTreatmentOptions] = useState();
  const [error, setError] = useState(false);
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [building_order, setBuildingOrder] = building_order_details;
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const history = useHistory();
  const [cart_items, setCartItems] = cartItems;
  const [UncheckedUnits, setUnCheckedUnits] = UncheckedUnitsData;
  const [checkedUnits, setCheckedUnits] = checkedUnitsData;
  const { locationID } = useParams();
  const [selectFile, setSelectFile] = useState(null);
  const [suitPostData, setSuitPostData] = useState(null);
  const { addToast } = useToasts();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    console.log('single_building_data',single_building_data)
    // if (single_building_data) {
    //   const sBData = building_info.filter((b) => b.location_id == locationID);
    //   setSelectedBuilding(sBData?.length && sBData[0]);
    //   var temp_b_order = { ...building_order };
    //   temp_b_order = {
    //     ...temp_b_order,
    //     building_name: (sBData && sBData[0]?.text) || "",
    //   };
    //   setBuildingOrder(temp_b_order);
    // } else {
    //   history.goBack();
    // }
  }, []);

  useEffect(() => {
    if (UncheckedUnits && UncheckedUnits !== "All") {
      // Create a copy of checkedUnits object
      const updatedCheckedUnits = { ...checkedUnits };
      // Extract floor and unit from UncheckedUnits
      const [floor, unit] = UncheckedUnits.split("-");
      // Construct the key for the unit
      const unitKey = `${floor}-${unit}`;
      // Delete the unit from the object
      delete updatedCheckedUnits[unitKey];
      // Set the updated object to state
      //getBuildingHeatmap()
      setCheckedUnits(updatedCheckedUnits);
      setUnCheckedUnits(null);
    } else if (UncheckedUnits == "All") {
      setCheckedUnits({});
      setUnCheckedUnits(null);
    }
  }, [UncheckedUnits, checkedUnits]);

  //This effect job is for suitspot
  // useEffect(() => {
  //   if (selected_building) {
  //     checkIsSuitSpot();
  //   }
  // }, [selected_building]);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "po_mo_wo") {
      value = value.replace(/^\s+/g, "");
    }
    setBuildingOrder({ ...building_order, [name]: value });
  };

  function checkIsSuitSpot() {
    if (
      selected_building.customer_name == "Park Property GTA" ||
      selected_building.customer_name == "Park property GTA"
    ) {
      setSelectFile(true);
    } else {
      setSelectFile(false);
    }
  }

  const add_unit = () => {
    setFormError([...form_validation_error, {}]);
    set_add_unit([
      ...var_add_unit,
      { unit_number: "",followup: "neworder", key: var_add_unit.length },
    ]);
  };

  function pushRoute(isDraft = false) {
    var isValid = true;
    var_add_unit.map((value, i) => {
      if (
        (selected_building.building_po_mo_wo && !building_order.po_mo_wo) ||
        form_validation_error[i]?.unit_number ||
        !value.unit_number ||
        !value.pest_id ||
        !value.treatment_id ||
        !value.followup
      ) {
        isValid = false;
      }
    });
    if (isValid) {
      ///technician-home/order-review
      if (user.role === "technician") {
        history.push("/technician-home/order-review");
      } else {
        history.push("/building-impersonate/order-review/", { isDraft });
      }
    } else {
      setError(true);
    }
  }

  const handleDeleteItem =  async(itemKey, index, unit_number) => {
    // Make a copy of cart_items
    const updatedCartItems = { ...cart_items };
    var DeleteUnitData = itemKey + "-" + unit_number;
    // Remove the item at the specified index from the cart_items
    const dataAfterRemove = updatedCartItems[itemKey].filter((dar)=> dar.floor === itemKey && dar.unit_number !== unit_number)
    // If the array becomes empty after deletion, remove it from cart_items
    setUnCheckedUnits(DeleteUnitData);
  

    if (dataAfterRemove.length === 0) {
      delete updatedCartItems[itemKey];
    }
    // //console.log('index', index)
    // console.log("iTEMKEY", itemKey,"UPDATEDCARTITEMS", updatedCartItems);
    // // Check if updatedCartItems is empty
    if (Object.keys(updatedCartItems).length === 0) {
      // Handle the case when cart_items becomes empty
      setCartItems(null);
    } else {
      let temp = {...updatedCartItems}
      // Update the state with the new cart_items
      setCartItems(temp);
    }
  };

  const doDelete = (index) => {
    var items = [...var_add_unit];
    // console.log(index)
    if (items[index].floor) {
      handleDeleteItem(items[index].floor, index, items[index]?.unit_number);
    }
    items.splice(index, 1);
    items.map((val, index) => {
      var temp = val;
      temp.key = index;
      items[index] = temp;
    });

    set_add_unit(items);
  };

  const onChangeFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      set_mdl_progress(true);
      let data = new FormData();
      data.append("document", file);
      data.append("buildingAddress", selected_building?.text);
      fetch(portalReadPdf, {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          set_mdl_progress(false);
          if (response.code == 200 && response.data) {
            let { buildingAddress, unitNumber, poNumber } = response.data;

            if (
              buildingAddress.includes(selected_building?.text) ||
              selected_building?.text.includes(buildingAddress)
            ) {
              setBuildingOrder({
                ...building_order,
                po_mo_wo: poNumber.replace(/^\s+/g, ""),
              });

              var temp = [...var_add_unit];
              temp[0] = { ...temp[0], unit_number: unitNumber };
              set_add_unit(temp);
              setSuitPostData(response.data);
            } else {
              addToast("This document is not related to selected building.", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          } else {
            addToast(response?.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          set_mdl_progress(false);
          console.log("Error:::", error);
        });
    }
  };

  function swap(a, b) {
    let  items  = var_add_unit;
    items[a] = items.splice(b, 1, items[a])[0];
    items?.forEach((items,index)=>{
      items.priority = index;
    })
    set_add_unit([...items])
  }
   
  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <div className="app-content">
      <div className="order-request-form-container">
        <div className="app-backto-prev-page">
          <div
            className="app-goback-button"
            onClick={() => {
              setFormError([{}]);
              setBuildingOrder({});
              set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
              history.goBack();
            }}
          >
            <Icon name="arrow left" /> Go Back
          </div>
        </div>
        <Form error={error} onSubmit={() => pushRoute()}>
          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-title">Service Request</div>
            </div>
            <div className="app-card-body">
              <Form.Group>
                <Form.Field
                  label="Location"
                  width={8}
                  control={Input}
                  required
                  value={single_building_data?.building_name}
                />
                {selectFile && (
                  <Form.Field
                    id="upload_file"
                    name="upload_file"
                    label="Please upload PO/MO"
                    control={Input}
                    width={8}
                    type="file"
                    onChange={onChangeFile}
                  />
                )}
                {
                  //    selected_building && selected_building.building_po_mo_wo?
                  <Form.Field
                    disabled={selectFile}
                    label={
                      selected_building.building_po_mo_wo?.toLowerCase() ===
                      "wo"
                        ? "Purchase Order Number"
                        : selected_building?.building_po_mo_wo?.toLowerCase() ===
                          "mo"
                        ? "Maintenance Order Number"
                        : "Purchase Order Number"
                    }
                    width={8}
                    control={Input}
                    required={selected_building?.building_po_mo_wo}
                    name="po_mo_wo"
                    id="po_mo_wo"
                    value={single_building_data.po_mo_wo}
                    placeholder={`${
                      single_building_data?.building_po_mo_wo?.toUpperCase() ||
                      "PO "
                    } number must be entered here before submitting request`}
                    onChange={onChangeInput}
                    maxLength={30}
                  />
                  // :null
                }
              </Form.Group>
            </div>
          </div>
          <Table unstackable>
                <Table.Header>
                  <Table.Row>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {var_add_unit && var_add_unit?.map((unit, index) => (
                    <DraggableRow key={index} i={index} action={swap.bind(unit)}>
                      <Table.Cell><div className="add-unit-container">
                      <ADD_UNIT key={index}
                        index={index}
                        width={8}
                        treatment={treatmentOption}
                        pest={pestTreated}
                        editable_unit={selectFile}
                        doDelete={doDelete.bind(this, index)}
                        isTech={user.role === "technician"}
                        pdfData={suitPostData}
                      />
                        </div>
                      </Table.Cell>
                    </DraggableRow>
                  ))}
                 
                </Table.Body>
              </Table>
          <Message
            error
            header="Required Fields"
            content="Please fill all required(*) fileds"
          />
          <div className="finish-request-actions">
            {!selectFile && (
              <>
                <Button
                  className="add-unit-button"
                  color="grey"
                  type="button"
                  onClick={() => add_unit()}
                >
                  <img src="/add-square.svg" alt="" />
                  Add Unit
                </Button>
                <Button color="orange" onClick={() => pushRoute(true)}>
                  Save as draft
                </Button>
              </>
            )}
            <Button color="red" type="submit">
              Finish Request
            </Button>
          </div>
        </Form>
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}

export default ImpersonateOrderRequestForm;
