import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Input, Icon, Message, Modal } from "semantic-ui-react";
import { useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import ADD_UNIT from "./cmp-add-unit/cmp-add-unit";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";

import "./OrderRequestForm.css";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { updateOrAddOrderDetails } from "../../../constants";

function OrderRequestFormScheduler() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    error_validation,
    userInfo,
    sbuildingData,
    selectedBuilding,
  } = useContext(AppContext);
  const [user] = userInfo;
  const [single_building_data] = sbuildingData;
  const [selected_building] = selectedBuilding;
  const [pestTreated, setPestTreated] = useState();
  const [form_validation_error, setFormError] = error_validation;
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [treatmentOption, setTreatmentOptions] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [building_order, setBuildingOrder] = building_order_details;
  const history = useHistory();
  const location = useLocation();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const { addToast } = useToasts();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    //setFormError([{}])
    setBuildingOrder({});
    if (!selected_building) {
      set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
      history.goBack();
    }
  }, []);
  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setBuildingOrder({ ...building_order, [name]: value });
  };

  const add_unit = () => {
    setFormError([...form_validation_error, {}]);
    set_add_unit([
      ...var_add_unit,
      {
        unit_number: "",
        isAdded: true,
        key: var_add_unit.length,
        followup: false,
      },
    ]);
  };

  async function pushRoute() {
    var isValid = true;

    var_add_unit.map((value, i) => {
      if (form_validation_error[i]?.unit_number) {
        isValid = false;
      }
      if (
        form_validation_error[i]?.unit_number ||
        (!value.isDeleted && (!value.pest_id || !value.treatment_id))
      ) {
        isValid = false;
      }
    });
    if (isValid) {
      set_mdl_progress(true);
      const { id, building_uuid, building_code, order_id } = location?.state;
      var params = {};
      params = { ...params, orderID: order_id };
      params = { ...params, building_code };
      params = { ...params, building_uuid };
      params = { ...params, id };
      var followUp = [];
      var notFollowUp = [];
      var followupMonth = [];
      var isExist = false; // If user added same unit # for bedbug followup and not followup
      var_add_unit
        .filter(function (el) {
          return el != null;
        })
        .map((value) => {
          value.is_reschedule = value.followup === "reschedule" ? true : false;
          //value.unit_number = value.unit_number.trim()
          value.unit_number = value.unit_number.replace(/^[.\s]+|[.\s]+$/g, "");
          if (value.followup === "followup") {
            value.followup = true;
            value.isFollowupMonthly = false;
            value.isImmediate = false;
            followUp = [...followUp, value];
          } else if (value.followup === "followup_month") {
            value.followup = false;
            value.isFollowupMonthly = true;
            value.isImmediate = false;
            followUp = [...followUp, value];
          } else if (value.followup === "followup_immediately") {
            value.followup = false;
            value.isFollowupMonthly = false;
            value.isImmediate = true;
            notFollowUp = [...followUp, value];
          } else {
            value.followup = false;
            value.isFollowupMonthly = false;
            value.isImmediate = false;
            notFollowUp = [...notFollowUp, value];
          }
        });

      followUp.map((value) => {
        notFollowUp.map((val) => {
          if (
            value.unit_number === val.unit_number &&
            value.pest === val.pest &&
            value.treatment === val.treatment
          ) {
            isExist = true;
          }
        });
      });
      if (!isExist) {
        notFollowUp.map((val) => {
          if (single_building_data?.second_bedbug) {
          if (
            !val.isImmediate &&
            !val.isFollowupMonthly &&
            val.pest === "Bed Bugs" &&
            val.treatment === "Spray Treatment"
          ) {
            var temp = { ...val };
            temp.followup = true;
            followUp = [...followUp, temp];
          }
        }
        });
      }
      followUp = alphaNumericSort(followUp, "unit_number");
      notFollowUp = alphaNumericSort(notFollowUp, "unit_number");
      followupMonth = followUp.filter((val) => val.isFollowupMonthly);
      followUp = followUp.filter((val) => !val.isFollowupMonthly);
      params = { ...params, not_followup_units: notFollowUp };
      params = { ...params, followup_units: followUp };
      params = { ...params, followup_monthly_units: followupMonth };
      const requestOptionsOrder = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };
      await fetch(updateOrAddOrderDetails, requestOptionsOrder)
        .then((res) => res.json())
        .then((response) => {
          set_mdl_progress(false);
          if (response.code === 200) {
            addToast("Order updated successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
            history.replace("/scheduler-home");
          } else {
            addToast(response.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          set_mdl_progress(true);
          console.log("Error:::", error);
        });
    } else {
      setError(true);
    }
  }

  const alphaNumericSort = (arr = [], key) => {
    const sorter = (x, y) => {
      var a = x[key];
      var b = y[key];
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.match(/\d+|\D+/g);
      const bPart = b.match(/\d+|\D+/g);
      let i = 0;
      let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) {
        i++;
      }
      if (i === len) {
        return aPart.length - bPart.length;
      }
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      }
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
    return arr;
  };

  const doDelete = (index) => {
    var items = [...var_add_unit];
    items[index].isDeleted = true;
    items[index].isAdded = false;
    items[index].isEdited = false;
    set_add_unit(items);
  };

  const doEdit = (index) => {
    var items = [...var_add_unit];
    items[index].isEdited = items[index].isAdded ? false : true;
    //items[index].isEdited = true;
    set_add_unit(items);
  };

  const success_function = () => {
    setBuildingOrder({});
    set_add_unit([{ unit_number: "", followup: true }]);
    set_mdl_success(false);
    history.replace("/scheduler-home");
  };
  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <div className="app-content">
      <div className="order-request-form-container">
        <div className="app-backto-prev-page">
          <div
            className="app-goback-button"
            onClick={() => {
              setFormError([{}]);
              setBuildingOrder({});
              set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
              history.goBack();
            }}
          >
            <Icon name="arrow left" /> Go Back 
          </div>
        </div>
        <Form error={error} onSubmit={() => pushRoute()}>
          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-title">Service Request</div>
            </div>
            <div className="app-card-body">
              <Form.Group>
                <Form.Field
                  label="Location"
                  control={Input}
                  required
                  value={selected_building?.text}
                />
                {selected_building && selected_building.building_po_mo_wo ? (
                  <Form.Field
                    label={
                      selected_building.building_po_mo_wo.toLowerCase() === "po"
                        ? "Purchase Order Number"
                        : selected_building?.building_po_mo_wo.toLowerCase() ===
                          "mo"
                        ? "Maintenance Order Number"
                        : "Work Order Number"
                    }
                    control={Input}
                    required={selected_building?.building_po_mo_wo}
                    name="po_mo_wo"
                    id="po_mo_wo"
                    disabled={true}
                    value={building_order.po_mo_wo}
                    placeholder={`${selected_building?.building_po_mo_wo.toUpperCase()} number must be entered here before submitting request`}
                    onChange={onChangeInput}
                    maxLength={30}
                  />
                ) : null}
              </Form.Group>
            </div>
          </div>
          <div className="add-unit-container">
            {var_add_unit.map(
              (unit, index) =>
                !unit.isDeleted && (
                  <ADD_UNIT
                    key={index}
                    index={index}
                    treatment={treatmentOption}
                    pest={pestTreated}
                    doDelete={doDelete.bind(this, index)}
                    doEdit={doEdit.bind(this, index)}
                    isTech={user.role === "technician"}
                  />
                )
            )}
          </div>
          <Message
            error
            header="Required Fields"
            content="Please fill all required(*) fileds"
          />
          <div className="finish-request-actions">
            <Button
              className="add-unit-button"
              color="grey"
              type="button"
              onClick={() => add_unit()}
            >
              <img src="/add-square.svg" alt="" />
              Add Unit
            </Button>
            <Button color="red" type="submit">
              Save Request
            </Button>
          </div>
        </Form>
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process can take one or two minutes. Stay on this page."
          }
          success_function={success_function}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}

export default OrderRequestFormScheduler;
