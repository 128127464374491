import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Icon,
  Message,
  Search,
  Modal,
  Grid,
} from "semantic-ui-react";
import { useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import MDL_SUCCESS from "../../../components/cmp-success/cmp-success";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_CONFIRMATION from "../../../components/cmp-confirmation/cmp-confirmation";
import ADD_UNIT from "./cmp-add-unit/cmp-add-unit";

import "./OrderRequestDraft.css";
import { postOrder, searchBuilding } from "../../../constants";
import { useToasts } from "react-toast-notifications";

function OrderRequestDraft() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    error_validation,
    userInfo,
    selectedBuilding,
    buildingUnits,
  } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [, setBuildingUnits] = buildingUnits;
  const [isLoading, setIsLoading] = useState(false);
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [search_value, set_search_value] = useState("");
  const [pestTreated, setPestTreated] = useState();
  const [form_validation_error, setFormError] = error_validation;
  const [treatmentOption, setTreatmentOptions] = useState();
  const [error, setError] = useState(false);
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [isLoadingBuilding, setIsLoadingBuilding] = useState(false);
  const [building_order, setBuildingOrder] = building_order_details;
  const [results, set_results] = useState([]);
  const [searched_building, set_searched_building] = useState();
  const [message, setMessage] = useState();
  const history = useHistory();
  const { addToast } = useToasts();
  const [open_to_update, set_open_to_update] = useState(true);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);
  
  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setBuildingOrder({ ...building_order, [name]: value });
  };

  const add_unit = () => {
    setFormError([...form_validation_error, {}]);
    set_add_unit([
      ...var_add_unit,
      { unit_number: "", key: var_add_unit.length },
    ]);
  };

  function pushRoute() {
    var isValid = true;
    var_add_unit.map((value, i) => {
      if (
        form_validation_error[i].unit_number ||
        !value.pest_id ||
        !value.treatment_id ||
        !value.followup
      ) {
        isValid = false;
      }
    });
    if (isValid) {
      ///technician-home/order-review
      postServiceOrder();
    } else {
      setError(true);
    }
  }
  const doDelete = (index) => {
    var items = [...var_add_unit];
    // console.log(index)
    items.splice(index, 1);
    items.map((val, index) => {
      var temp = val;
      temp.key = index;
      items[index] = temp;
    });
    // for(var i=0 ; i < var_add_unit.length ; i++){
    //     if(i != index){
    //         console.log(i,index);
    //         var temp={...var_add_unit[i]};
    //         temp.key=index;
    //         items[i]= temp;
    //     }
    // }
    set_add_unit(items);
    // items=items.filter(value=>value.key!=index)
    // items.map((val,index)=>{
    //     var temp=val;
    //     temp.key=index;
    //     items[index]=temp;
    // })
  };

  const handleResultSelect = (e, { result }) => {
    set_search_value(result?.title);
    setBuildingUnits(result.units ? result.units : []);
    set_open_to_update(false);
    set_searched_building(result);
  };

  const handleSearchChange = async (e, { value }) => {
    if (open_to_update) {
      const { user_id } = JSON.parse(localStorage.getItem("user"));
      set_search_value(value);
      if (value.length > 2) {
        setIsLoadingBuilding(true);
        await fetch(searchBuilding + value+"&schedulerID="+user_id)
          .then((res) => res.json())
          .then((response) => {
            setIsLoadingBuilding(false);
            set_results(response.data);
          });
      } else {
        set_searched_building(null);
      }
    } else {
      set_mdl_confirmation(true);
    }
  };

  async function postServiceOrder() {
    var DateNTime = new Date().toISOString();
    var params = {};
    params = { ...params, orderDateAndTime: DateNTime };
    params = { ...params, orderDate: new Date().toISOString() };
    params = { ...params, building_uuid: searched_building.uuid };
    params = { ...params, requested_by: user.uuid };
    params = { ...params, accepted_by: null };
    params = { ...params, po_mo_wo: building_order.po_mo_wo };
    params = { ...params, LocationID: searched_building.location_id };
    var followUp = [];
    var notFollowUp = [];
    var followupMonth = [];
    var isExist = false; // If user added same unit # for bedbug followup and not followup
    var_add_unit
      .filter(function (el) {
        return el != null;
      })
      .map((value) => {
        value.is_reschedule = value.followup === "reschedule" ? true : false;
        //value.unit_number = value.unit_number.trim()
        value.unit_number = value.unit_number.replace(/^[.\s]+|[.\s]+$/g, "");
        if (value.followup === "followup") {
          value.followup = true;
          value.isImmediate = false;
          followUp = [...followUp, value];
        } else if (value.followup === "followup_month") {
          value.followup = false;
          value.isFollowupMonthly = true;
          followUp = [...followUp, value];
        } else if (value.followup === "followup_immediately") {
          value.followup = false;
          value.isImmediate = true;
          notFollowUp = [...notFollowUp, value];
        } else {
          value.followup = false;
          value.isImmediate = false;
          value.isFollowupMonthly = false;
          notFollowUp = [...notFollowUp, value];
        }
      });

    followUp.map((value) => {
      notFollowUp.map((val) => {
        
        if (
          value.unit_number === val.unit_number &&
          value.pest === val.pest &&
          value.treatment === val.treatment
        ) {
          isExist = true;
        }
      });
    });
    if (!isExist) {
      notFollowUp.map((val) => {
        if (searched_building?.second_bedbug) {
        if (
          !val.isImmediate &&
          !val.isFollowupMonthly &&
          val.pest === "Bed Bugs" &&
          val.treatment === "Spray Treatment"
        ) {
          var temp = { ...val };
          temp.followup = true;
          followUp = [...followUp, temp];
        }
      }
      });
    }
    followUp = alphaNumericSort(followUp, "unit_number");
    notFollowUp = alphaNumericSort(notFollowUp, "unit_number");
    followupMonth = followUp.filter((val) => val.isFollowupMonthly);
    followUp = followUp.filter((val) => !val.isFollowupMonthly);

    params = { ...params, not_followup_units: notFollowUp };
    params = { ...params, followup_units: followUp };
    params = { ...params, followup_monthly_units: followupMonth };

    const requestOptionsOrder = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    setIsLoading(true);
    set_mdl_progress(true);
    await fetch(postOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          localStorage.removeItem("building");
          setUser(null);
          setBuildingInfo(null);
          setBuildingOrder({});
        } else {
          const { message } = res;
          if (res.code === 200) {
            setMessage(message);
            progress_function();
          } else {
            set_mdl_progress(false);
            addToast(
              message ? message : "Some error occured. Please try again.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        }
      })
      .catch(() => {
        set_mdl_progress(false);
        addToast("Server side error. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    setIsLoading(false);
  }

  const progress_function = () => {
    set_mdl_progress(false);
    set_mdl_success(true);
  };
  const success_function = () => {
    setBuildingOrder({});
    set_add_unit([{ unit_number: "", followup: true }]);
    set_mdl_success(false);
    history.replace("/scheduler-home");
  };

  const alphaNumericSort = (arr = [], key) => {
    const sorter = (x, y) => {
      var a = x[key];
      var b = y[key];
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.match(/\d+|\D+/g);
      const bPart = b.match(/\d+|\D+/g);
      let i = 0;
      let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) {
        i++;
      }
      if (i === len) {
        return aPart.length - bPart.length;
      }
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      }
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
    return arr;
  };
  /* RENDER APP
	---------------------------------------------------------------------------------*/

  const changeBuilding = () => {
    set_open_to_update(true);
    set_add_unit([{ unit_number: "", key: var_add_unit.length }]);
    set_searched_building(null);
  };

  return (
    <div className="app-content">
      <div className="order-request-form-container">
        <div className="app-backto-prev-page">
          <div
            className="app-goback-button"
            onClick={() => {
              setFormError([{}]);
              setBuildingOrder({});
              set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
              history.goBack();
            }}
          >
            <Icon name="arrow left" /> Go Back
          </div>
        </div>
        <Form error={error} onSubmit={() => pushRoute()}>
          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-title">Draft Service Request</div>
            </div>
            <div className="app-card-body">
              <div className="darft-search-toolbar">
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <div className="draft-search-building">
                        <label>Search Building</label>
                        <Search
                          fluid
                          loading={isLoadingBuilding}
                          onResultSelect={handleResultSelect}
                          onSearchChange={handleSearchChange}
                          results={results}
                          input={{
                            placeholder: "Search building...",
                            fluid: true,
                          }}
                          value={search_value}
                        />
                      </div>
                    </Grid.Column>
                    {/* {searched_building &&
                                    <Grid.Column>
                                            <Form.Field
                                                label="Location"
                                                control={Input}
                                                required
                                                value={searched_building?.title}
                                                fluid
                                            />
                                    </Grid.Column>
                                } */}
                    {searched_building &&
                    searched_building.building_po_mo_wo ? (
                      <Grid.Column>
                        <Form.Field
                          label={
                            searched_building.building_po_mo_wo.toLowerCase() ===
                            "po"
                              ? "Purchase Order Number"
                              : searched_building?.building_po_mo_wo.toLowerCase() ===
                                "mo"
                              ? "Maintenance Order Number"
                              : "Work Order Number"
                          }
                          control={Input}
                          required={searched_building?.building_po_mo_wo}
                          name="po_mo_wo"
                          id="po_mo_wo"
                          value={building_order.po_mo_wo}
                          disabled={!searched_building?.building_po_mo_wo}
                          placeholder={`${searched_building?.building_po_mo_wo.toUpperCase()} number must be entered here before submitting request`}
                          onChange={onChangeInput}
                          fluid
                          maxLength={30}
                        />
                      </Grid.Column>
                    ) : null}
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>
          <div className="add-unit-container">
            {searched_building &&
              var_add_unit.map((unit, index) => (
                <ADD_UNIT
                  key={index}
                  index={index}
                  treatment={treatmentOption}
                  pest={pestTreated}
                  doDelete={doDelete.bind(this, index)}
                  isTech={user.role === "technician"}
                />
              ))}
          </div>
          <Message
            error
            header="Required Fields"
            content="Please fill all required(*) fileds"
          />
          <div className="finish-request-actions">
            <Button
              disabled={!searched_building}
              className="add-unit-button"
              color="grey"
              type="button"
              onClick={() => add_unit()}
            >
              <img src="/add-square.svg" alt="" />
              Add Unit
            </Button>
            <Button disabled={!searched_building} color="red" type="submit">
              Send Draft
            </Button>
          </div>
        </Form>
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process can take one or two minutes. Stay on this page."
          }
          success_function={progress_function}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: SUCCESS **************************************************************************/}
      <Modal
        id="mdl-success"
        dimmer={"inverted"}
        open={var_mdl_success}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {
          set_mdl_success(false);
        }}
      >
        <MDL_SUCCESS
          set_mdl_open={set_mdl_success}
          var_modaltitle="Success"
          var_message={message}
          success_function={success_function}
        ></MDL_SUCCESS>
      </Modal>

      {/***** END MODAL: SUCCESS **************************************************************************/}

      {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
      <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Change Building"
          var_message="By doing this filled data will be deleted?"
          confirmation_function={changeBuilding}
        ></MDL_CONFIRMATION>
      </Modal>
    </div>
  );
}

export default OrderRequestDraft;
