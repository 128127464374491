import * as React from "react";

const RequestOrderIcon = (props) => {
  const [fill, setFill] = React.useState(props?.fill || "red");

  React.useEffect(() => {
    setFill(props?.fill);
  }, [props]);

  return (
    <svg
      width={30.947}
      height={29.224}
      id="Layer_1"
      {...props}
      style={{
        fill: "blue",
      }}
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
        cx="176"
        cy="416"
        r="32"
      />
      <circle
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
        cx="400"
        cy="416"
        r="32"
      />
      <g id="shopping-cart">
        <path
          fill={fill}
          stroke={fill}
          strokeWidth={0.5}
          d="M456.8,120.78A23.92,23.92,0,0,0,438.24,112H133.89l-6.13-34.78A16,16,0,0,0,112,64H48a16,16,0,0,0,0,32H98.58l45.66,258.78A16,16,0,0,0,160,368H416a16,16,0,0,0,0-32H173.42l-5.64-32H409.44A24.07,24.07,0,0,0,433,284.71l28.8-144A24,24,0,0,0,456.8,120.78Z"
        />
      </g>
    </svg>
  );
};
export default RequestOrderIcon;
