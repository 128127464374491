import React, { useState,useContext } from 'react'
import { AppContext } from "../../../../Context/AppContext";
import { Form, Icon, Input, Button } from 'semantic-ui-react'

const MDL_NOTE = ({ set_mdl_open,var_cart_note,set_cart_note,var_cart_item,var_cart_index }) => {
/* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const {cartItems} = useContext(AppContext);
    const [cart_items, setCartItems] = cartItems;
/* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

 /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

/* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    function closeModal() {
        set_mdl_open(false);
    }

    function onChangeInput(e) {
        set_cart_note(e.target.value);
    }

    function submitNote() {
        const updatedCartItems = { ...cart_items };
        updatedCartItems[var_cart_item][var_cart_index].order_description = var_cart_note;
        setCartItems(updatedCartItems);
        set_mdl_open(false);
    }

/* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <Form>
                <div className="modal-header">
                    <div className="modal-header-title">Notes</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                        <Icon name="times circle outline" />
                    </div>
                </div>
                <div className='modal-content'>
                    <Form.Group>
                    <Form.TextArea
                        id="order_description"
                        name="order_description"
                        width={16}
                        value={var_cart_note}
                        placeholder="Please add any additional info for this unit."
                        fluid
                        onChange={onChangeInput}
                        />
                    </Form.Group>
                </div>
                <div className="modal-footer">
                    <Button color="red" onClick={() => closeModal()}>Cancel</Button>
                    <Button color="green"  onClick={() => submitNote()}>Submit</Button>
                </div>
            </Form>
        </>
    )
}

export default MDL_NOTE