import dateFormat from "dateformat";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer, Loader, Modal, Button } from "semantic-ui-react";
import MDL_SUCCESS from "../../../../components/cmp-success/cmp-success";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import { postOrder } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";

function OrderReview() {
  /* VARIABLE DECLARATIONS
    ---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    userInfo,
    selectedBuilding,
    sbuildingData,
    cartItems,
    UncheckedUnitsData,
  } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [selected_building] = selectedBuilding;
  const [, setCartItems] = cartItems;
  const [, setUnCheckedUnits] = UncheckedUnitsData;
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [single_building_data] = sbuildingData;
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [building_order, setBuildingOrder] = building_order_details;
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { addToast } = useToasts();
  /* ASYNC FUNCTIONS
    ---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
    ---------------------------------------------------------------------------------*/
  useEffect(() => {
    if (!building_info) {
      history.goBack();
    }
    if (!building_order.building_name) {
      history.goBack();
    }
  });

  /* ASYNC FUNCTIONS
    ---------------------------------------------------------------------------------*/
  const mdl_succes_onOpen = async () => {
    var params = {};
    params = {
      ...params,
      orderDateAndTime:
        building_order.orderDate + " " + building_order.orderTime,
    };
    params = { ...params, orderDate: new Date().toISOString() };
    params = { ...params, building_uuid: selected_building.value };
    params = {
      ...params,
      requested_by:
        user.role === "technician"
          ? user.uuid
          : history.location?.state?.isDraft
          ? null
          : selected_building.value,
    };
    params = { ...params, accepted_by: null };
    params = { ...params, po_mo_wo: building_order.po_mo_wo };
    params = { ...params, LocationID: selected_building.location_id };
    var followUp = [];
    var notFollowUp = [];
    var followupMonth = [];
    var isExist = false; // If user added same unit # for bedbug followup and not followup
    var_add_unit
      .filter(function (el) {
        return el != null;
      })
      .map((value) => {
        value.is_reschedule = value.followup === "reschedule" ? true : false;
        //value.unit_number = value.unit_number.trim()
        value.unit_number = value.unit_number.replace(/^[.\s]+|[.\s]+$/g, "");
        if (value.followup === "followup") {
          value.followup = true;
          value.isImmediate = false;
          followUp = [...followUp, value];
        } else if (value.followup === "followup_month") {
          value.followup = false;
          value.isFollowupMonthly = true;
          value.isImmediate = false;
          followUp = [...followUp, value];
        } else if (value.followup === "followup_immediately") {
          value.followup = false;
          value.isImmediate = true;
          notFollowUp = [...notFollowUp, value];
        } else {
          value.followup = false;
          value.isImmediate = false;
          value.isFollowupMonthly = false;
          notFollowUp = [...notFollowUp, value];
        }
      });
    followUp.map((value) => {
      notFollowUp.map((val) => {
        if (
          value.unit_number === val.unit_number &&
          value.pest === val.pest &&
          value.treatment === val.treatment
        ) {
          isExist = true;
        }
      });
    });
    if (!isExist) {
      notFollowUp.map((val) => {
        if (single_building_data?.second_bedbug) {
          if (
            !val.isImmediate &&
            !val.isFollowupMonthly &&
            val.pest === "Bed Bugs" &&
            val.treatment === "Spray Treatment"
          ) {
            var temp = { ...val };
            temp.followup = true;
            followUp = [...followUp, temp];
          }
        }
      });
    }
    followUp = alphaNumericSort(followUp, "unit_number");
    notFollowUp = alphaNumericSort(notFollowUp, "unit_number");
    followupMonth = followUp.filter((val) => val.isFollowupMonthly);
    followUp = followUp.filter((val) => !val.isFollowupMonthly);

    params = { ...params, not_followup_units: notFollowUp };
    params = { ...params, followup_units: followUp };
    params = { ...params, followup_monthly_units: followupMonth };
    const requestOptionsOrder = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    setIsLoading(true);
    set_mdl_progress(true);
    await fetch(postOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          localStorage.removeItem("building");
          setUser(null);
          setBuildingInfo(null);
          setBuildingOrder({});
        } else {
          const { message } = res;
          if (res.code === 200) {
            setUnCheckedUnits("All");
            setMessage(message);
            progress_function();
          } else {
            set_mdl_progress(false);
            addToast(
              message ? message : "Some error occured. Please try again.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        }
      })
      .catch(() => {
        set_mdl_progress(false);
        addToast("Server side error. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    setIsLoading(false);
  };
  const progress_function = () => {
    set_mdl_progress(false);
    set_mdl_success(true);
    setCartItems(null);
  };
  const success_function = () => {
    setBuildingOrder({});
    set_add_unit([{ unit_number: "", followup: true }]);
    set_mdl_success(false);
    if (user.role === "technician") {
      setBuildingInfo({});
      history.replace("/technician-home");
    } else {
      history.replace("/home");
    }
  };

  const alphaNumericSort = (arr = [], key) => {
    const sorter = (x, y) => {
      var a = x[key];
      var b = y[key];
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.match(/\d+|\D+/g);
      const bPart = b.match(/\d+|\D+/g);
      let i = 0;
      let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) {
        i++;
      }
      if (i === len) {
        return aPart.length - bPart.length;
      }
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      }
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
    return arr;
  };

  function getOrderType(value) {
    if (value.followup && value.followup === "followup") {
      return "Follow Up (Service In 2 Weeks)";
    } else if (value.followup && value.followup === "reschedule") {
      return "Reschedule";
    } else if (value.followup && value.followup === "followup_immediately") {
      return "Follow Up (Service Immediately)";
    } else if (value.followup && value.followup === "followup_month") {
      return "Follow Up (Service In Month)";
    } else {
      return "New";
    }
  }

  /* RENDER APP
    ---------------------------------------------------------------------------------*/
  return (
    <>
      <div className="app-content">
        <div className="order-review-contaienr">
          <div className="order-review-header">
            <div className="order-review-title">
              Final Treatment Request - For Review
            </div>
            <div className="order-review-subtitle">
              <span>Building:</span>
              <span>{building_order.building_name}</span>
            </div>
            <div className="order-review-subtitle">
              <span>Date &amp; Time: </span>
              {/* <span>{building_order.orderDate} {building_order.orderTime}</span> */}
              <span>{dateFormat(new Date(), "yyyy-mm-dd h:MM:ss")}</span>
            </div>
            {building_info && building_info.building_po_mo_wo ? (
              <div className="order-review-subtitle">
                <span>
                  {building_info.building_po_mo_wo.toString().toLowerCase() ===
                  "po"
                    ? "Purchase Order Number"
                    : building_info.building_po_mo_wo
                        .toString()
                        .toLowerCase() === "mo"
                    ? "Maintenance Order Number"
                    : "Work Order Number"}
                  :
                </span>
                <span>{building_order.po_mo_wo}</span>
              </div>
            ) : null}
          </div>
          {var_add_unit.map((value) => (
            <div className="order-review-unit">
              <div className="app-card">
                <div className="unit-detail-row">
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Unit #</div>
                    <div className="unit-detail-value">{value.unit_number}</div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Treatment Requested</div>
                    <div className="unit-detail-value">{value.treatment}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="page-form-submit">
            {!isLoading && (
              <div>
                <div className="page-form-submit-button">
                  <Button color="red" onClick={() => mdl_succes_onOpen()}>
                    {history.location?.state?.isDraft
                      ? "Save Draft"
                      : "Submit Request"}
                  </Button>
                </div>
                <div className="page-goback">
                  <div className="page-goback-link" onClick={history.goBack}>
                    Go Back
                  </div>
                  <div className="page-goback-message">
                    (If you want to edit the information)
                  </div>
                </div>
              </div>
            )}
            {isLoading && <Loader active inline />}
          </div>
        </div>
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process can take one or two minutes. Stay on this page."
          }
          success_function={progress_function}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: SUCCESS **************************************************************************/}
      <Modal
        id="mdl-success"
        dimmer={"inverted"}
        open={var_mdl_success}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {
          set_mdl_success(false);
        }}
      >
        <MDL_SUCCESS
          set_mdl_open={set_mdl_success}
          var_modaltitle="Success"
          var_message={message}
          success_function={success_function}
        ></MDL_SUCCESS>
      </Modal>

      {/***** END MODAL: SUCCESS **************************************************************************/}
    </>
  );
}

export default OrderReview;
